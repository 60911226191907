<template>
    <th :class="classes">
        <slot />
    </th>
</template>

<script>
export default {
    props: {
        fullWidth: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },
    data() {
        return {
            baseClasses: "py-3.5 px-default text-left text-sm font-medium text-gray-400 whitespace-nowrap",
        };
    },
    computed: {
        classes() {
            return `table-header ${this.baseClasses} ${this.fullWidth ? "w-full" : ""}`;
        },
    },
    created() {
        this.$emit("created");
    },
};
</script>
